import React from "react"
import Image from "gatsby-image";
import "../../styles/offer.scss"
import OfferHeader from "../../components/OfferHeader";
import OfferItem from "../../components/OfferItem"
import SEO from "../../components/seo"
import { graphql } from "gatsby"

const dataTailor = {
    header: "Wykonujemy profesjonalne naprawy i przeróbki krawieckie",
    text: "Usługi, takie jak: skracanie, zwężanie, poszerzanie, zmiany fasonów odzieży oraz inne modyfikacje przeprowadzamy na najwyższym poziomie. Nasz zakład oferuje również wymianę uszkodzonych kołnierzy, podszewek czy zamków błyskawicznych.",
    link: "cennik.krawiectwo",
    headerItem1: "1. Wybór materiału",
    descItem1: "Przy pierwszym spotkaniu krawiec szczegółowo rozmawia z klientem o jego oczekiwaniach. Należy wybrać, materiał, kolor i skład materiału. Tkaninę należy dopasować do pory roku oraz okoliczności.",
    headerItem2: "2. Detale",
    descItem2: "Po wyborze tkaniny i kroju nadchodzi czas na wybór dodatków. Z przyjemnością odpowiemy na wszystkie pytania. Indywidualnie dobrane detale, takie jak: guziki, kolory nici czy podszewka podkreślą wyjątkowość projektowanej odzieży.",
    headerItem3: "3. Zdejmowanie miary",
    descItem3: "Podczas tej samej wizyty zostanie dobrany odpowiedni krój. To najważniejszy krok dopasowania zamawianego produktu. We wszystkim doradzimy oraz pomożemy dopasować kreację do okoliczności, figury oraz trendów.",
}

const description = "Oferujemy przeróbki krawieckie: skracanie, zwężanie, wymiana zamków. Konin"
const keywords = ["usługi krawieckie konin"]

const TailorOfferPage = ({ data }) => (
    <>
        <SEO
            title="Oferta | Krawiec"
            description={description}
            keywords={keywords}
        />
        <section className="offerPage container">
            <OfferHeader
                headerOffer={dataTailor.header}
                textOffer={dataTailor.text}
            />

            <div className="imgContainer">

                <div className="vertical tailor">
                    <div className="threads"><Image fluid={data.threads.childImageSharp.fluid} /></div>
                    <div className="sketch"><Image fluid={data.sketch.childImageSharp.fluid} /></div>
                </div>
                <div className="cloathes"><Image fluid={data.cloathes.childImageSharp.fluid} /></div>
                <div className="machine"><Image fluid={data.machine.childImageSharp.fluid} /></div>
            </div>

            <div className="realizations realizationsTailor">
                <div className="header">Szycie na miarę</div>
                <div className="desc">
                    Skorzystaj z indywidualnej usługi szycia na miarę, która pozwala na stworzenie odzieży dopasowanej do Twoich potrzeb i preferencji. Efekty usługi, poparte wieloletnim doświadczeniem zadowolą nawet najbardziej wymagających Klientów.
                </div>
            </div>
            <OfferItem
                headerItem={dataTailor.headerItem1}
                descItem={dataTailor.descItem1}
                img={data.project.childImageSharp.fluid}
            />
            <div className="testxd"></div>
            <OfferItem
                headerItem={dataTailor.headerItem2}
                descItem={dataTailor.descItem2}
                img={data.shirt.childImageSharp.fluid}
                line="line lineRight"
                leftImg
            />
            <OfferItem
                headerItem={dataTailor.headerItem3}
                descItem={dataTailor.descItem3}
                img={data.measure.childImageSharp.fluid}
                line="line lineLeft"
            />
        </section>
    </>
)

export const query = graphql`
  {
    cloathes: file(name: {eq: "tail_cloathes"}) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid_noBase64
            }
        }
    },
    machine: file(name: {eq: "tail_machine"}) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid_noBase64
            }
        }
    },
    sketch: file(name: {eq: "tail_sketch"}) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid_noBase64
            }
        }
    },
    threads: file(name: {eq: "tail_threads"}) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid_noBase64
            }
        }
    },
    
    measure: file(name: {eq: "tail_measure"}) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid_noBase64
            }
        }
    },

    project: file(name: {eq: "tail_project"}) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid_noBase64
            }
        }
    },
    shirt: file(name: {eq: "tail_shirt"}) {
        childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid_noBase64
            }
        }
    },
    containers: allFile(filter: {extension: {regex: "/(jpeg|jpg|gif|png)/"}, relativeDirectory: {eq: "tailor/realizations"}}) {
    edges {
      node {
        childImageSharp {
          fluid (maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  },

  }
`

export default TailorOfferPage
